@import '../../../styles/var';

.soWhat {
    margin-top: 100px;
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
    }
    .text {
        text-align: center;
        width: 900px;
        margin: 30px auto 0 auto;
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        @media(max-width: $md1+px) {
            font-size: 18px;
            width: 810px;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        @media(max-width: $md6+px) {
            font-size: 13px;
        }
    }
}