@import '../../../styles/var';

.startAppRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 150px;
  // padding-bottom: 150px;
  @media(max-width: $md5+px) {
    margin-top: 50px;
    // padding-bottom: 50px;
  }
  .startAppCol7 {
    flex: 0 0 auto;
    width: 58.33333333%;
    @media(max-width: $md5+px) {
      width: 100%;
    }
    .startAppForm {
      max-width: 580px;
      .col6 {
        flex: 0 0 auto;
        width: 50%;
        display: flex;
      }
      .startAppWrapInput {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 17px;
        .startAppCol3Input {
          flex: 0 0 auto;
          width: 33.33333333%;
          padding: 15px;
          background-color: #15151D;
        }
        .startAppCol9Input {
          width: 66.66666667%;
          padding: 15px;
          background-color: #15151D;
          &::placeholder {
            font-size: 18px;
            font-weight: 300;
            color: #fff;
          }
        }
        .startAppCol6Input {
          padding: 15px;
          background-color: #15151D;
          &::placeholder {
            font-size: 18px;
            font-weight: 300;
            color: #fff;
          }
        }
        .startAppWrapPhoneInput {
          display: flex;
          column-gap: 20px;
          margin-left: 15px;
        }
        .startAppInput {
          width: 100%;
          padding: 15px;
          background-color: #15151D;
          color: #fff;
          font-size: 16px;
          &::placeholder {
            font-size: 18px;
            font-weight: 300;
            color: #fff;
          }
        }
        select {
          width: 100%;
          font-size: 20px;
          font-weight: 300;
          color: #fff;
          option {
            color: #fff;
          }
        }
        .startAppSelect {

        }
      }
      .startAppCheckboxWrap {
        font-size: 11px;
        font-weight: 300;
        line-height: 1.5;
        color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        &.active {
          span {
            position: relative;
            display: block;
            height: 30px;
            width: 30px;
            margin-right: 20px;
            border: 1px solid #8F8F92;
            &:after {
              display: block;
            }
          }
        }
        span {
          position: relative;
          display: block;
          width: 30px;
          height: 30px;
          margin-right: 20px;
          border: 1px solid #8F8F92;
          &:after {
            content: "";
            background-color: #57e9eb;
            bottom: 0;
            height: 20px;
            left: 0;
            margin: auto;
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            transition: all .3s linear;
            width: 20px;
          }
        }
      }
    }
  }
  .startAppTitle {
    font-size: 35px;
    margin-bottom: 30px;
    @media(max-width: $md5+px) {
      font-size: 18px;
    }
    &.mb40 {
      margin-bottom: 40px;
      @media(max-width: $md5+px) {
        margin-bottom: 15px;
      }
    }
  }
  .startAppCol5 {
    flex: 0 0 auto;
    width: 41.66666667%;
    @media(max-width: $md5+px) {
      width: 100%;
      margin-top: 50px;
    }
    .startAppSubTitle {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      @media(max-width: $md5+px) {
        font-size: 18px;
      }
    }
    .startAppSubText {
      font-size: 20px;
      margin-bottom: 30px;
      @media(max-width: $md5+px) {
        font-size: 13px;
      }
    }
  }
  .me15 {
    margin-right: 15px;
  }
  .ms15 {
    margin-left: 15px;
  }
  .startAppLink {
    margin-top: 50px;
  }
}
