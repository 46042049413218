@import '../../../styles/var';

.builtBy {
    margin-top: 150px;
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
        @media(max-width: $md6+px) {
            text-align: left;
        }
    }
    .text {
        margin: 30px auto 50px auto;
        text-align: center;
        width: 792px;
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        color: #FFFFFF;
        @media(max-width: $md1+px) {
            font-size: 18px;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        @media(max-width: $md6+px) {
            font-weight: 300;
            font-size: 13px;
            line-height: 20px;
            text-align: left;
        }
    }
    .cards {
        display: flex;
        justify-content: space-evenly;
        @media(max-width: $md3+px) {
            display: grid;
            grid-template: repeat(2,1fr)/repeat(2,0fr);
            gap: 30px 0;
        }
        @media(max-width: $md6+px) {
            grid-template: repeat(4,1fr)/repeat(1,0fr);
            gap: 32px 0;
        }
        .card {
            position: relative;
            border-radius: 15px;
            width: 317px;
            height: 229px;
            text-align: center;
            border: 2px solid rgba(255, 255, 255, 0.1);
            @media(max-width: $md1+px) {
                width: 280px;
                height: 200px;
            }
            @media(max-width: $md2+px) {
                width: 230px;
                height: 170px;
            }
            @media(max-width: $md6+px) {
                width: 260px;
                height: 187px;
            }
            &Content {
                position: relative;
                z-index: 4;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) -30.29%, rgba(255, 255, 255, 0) 144.92%);
                backdrop-filter: blur(25px);
                height: 100%;
                width: 100%;
                border-radius: 15px;
                transition: all .3s;
                &:hover {
                    backdrop-filter: blur(5px);
                }
            }
            &Icon {
                position: relative;
                margin-top: 32px;
                @media(max-width: $md1+px) {
                    width: 60px;
                    margin-top: 28px;
                }
                @media(max-width: $md2+px) {
                    margin-top: 20px;
                }
                @media(max-width: $md6+px) {
                    margin-top: 22px;
                }
            }
            &Light {
                position: absolute;
                z-index: 3;
            }
            &Text {
                position: relative;
                font-weight: 500;
                font-size: 20px;
                line-height: 34px;
                text-align: center;
                color: #BDF3FF;
                width: 130px;
                text-align: center;
                margin: 30px auto 0 auto;
                @media(max-width: $md1+px) {
                    font-size: 18px;
                    line-height: 30px;
                    margin-top: 20px;
                    width: 120px;
                }
                @media(max-width: $md2+px) {
                    margin-top: 15px;
                }
                @media(max-width: $md6+px) {
                    font-size: 16.3522px;
                    line-height: 27px;
                    width: 110px;
                    margin-top: 22px;
                }
            }
            &:nth-child(1) {
                .cardLight {
                    top: -80px;
                    left: -10px;
                }
            }
            &:nth-child(2) {
                .cardLight {
                    top: -80px;
                    left: -10px;
                }
            }
            &:nth-child(3) {
                .cardLight {
                    top: -80px;
                    right: 0px;
                }
            }
            &:nth-child(4) {
                .cardText {
                    width: 90%;
                }
                .cardLight {
                    top: -90px;
                    left: 10%;
                }
            }
        }
    }
}