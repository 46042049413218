@import "../../../styles/_var.scss";
.fees {
    position: relative;
    z-index: 2;
}
.feesBody {
}
.feesRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
    padding-right: 100px;
    @media (max-width: $md1+px) {
        padding-right: 0;
    }
}
.feesRowLeft {
    flex: 0 1 556px;
    @media (max-width: $md3+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.feesTitle {
    text-align: left !important;
    margin-bottom: 30px;
    @media (max-width: $md1+px) {
        text-align: left !important;
    }
    @media (max-width: $md2+px) {
        text-align: left !important;
    }
    @media (max-width: $md3+px) {
        text-align: left !important;
    }
}

.feesBlock {
    margin-bottom: 15px;
}
.feesBlockTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.3;
    color: #ffffff;
    margin-bottom: 5px;
}
.feesBlockSubtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;
    color: #ffffff;
}

.feesRowRight {
    position: relative;
    top: -25px;
    @media (max-width: $md3+px) {
        flex: 0 1 400px;
        top: -15px;
        img {
            width: 100%;
        }
    }
    @media (max-width: $md6+px) {
        flex: 0 1 300px;
    }
}
