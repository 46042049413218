@import '../../../styles/var';

.canTradeTitle {
  font-size: 50px;
  text-align: center;
  margin: 100px auto;
  @media (max-width: $md3+px) {
    font-size: 25px;
    margin: 30px auto;
  }
}

.canTradeWrap {
  overflow-x: auto;
  overflow-y: hidden;

  .canTradeList {
    display: flex;
    flex-wrap: wrap;
    column-gap: 60px;
    @media(max-width: $md5+px) {
      flex-wrap: nowrap;
      width: 100%;
      margin-top: 30px;
    }
    @media (max-width: $md2+px) {
      width: 952px;
      margin-bottom: 10px;
    }
    @media (max-width: $md3+px) {
      width: 850px;
    }
    @media (max-width: $md5+px) {
      width: 520px;
      column-gap: 20px;
    }
    .canTradeItem {
      flex: 1 0 0%;
      @media (max-width: $md5+px) {
        flex: 0 0 auto;
        width: 25%;
      }
      .canTradeItemTitle {
        font-size: 20px;
        font-weight: 800;
        color: #57E9EB;
        margin-bottom: 20px;
        text-align: center;
        @media (max-width: $md5+px) {
          font-size: 15px;
        }
      }
      .canTradeItemText {
        font-size: 18px;
        text-align: center;
        @media (max-width: $md5+px) {
          font-size: 14px;
        }
      }
    }
  }
}

.canTradeLinkWrap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
