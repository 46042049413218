@import '../../../styles/var';

.whatEnergizes {
    margin-top: 100px;
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
    }
    .cards {
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;
        @media(max-width: $md3+px) {
            display: grid;
            grid-template: repeat(2,1fr)/repeat(3,1fr);
            gap: 40px 0;
        }
        @media(max-width: $md5+px) {
        }
        .card {
            text-align: center;
            @media(max-width: $md3+px) {
                &:nth-child(1) {
                    grid-row-start: 1;
                    grid-row-end: 1;
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
                &:nth-child(2) {
                    grid-row-start: 1;
                    grid-row-end: 1;
                    grid-column-start: 2;
                    grid-column-end: 4;
                }
                &:nth-child(3) {
                    grid-row-start: 2;
                    grid-row-end: 2;
                }
                &:nth-child(4) {
                    grid-row-start: 2;
                    grid-row-end: 2;
                }
                &:nth-child(5) {
                    grid-row-start: 2;
                    grid-row-end: 2;
                }
            }
            &:hover {
                .icon {
                    scale: 1.05;
                }
            }
            .icon {
                transition: all .3s;
                height: 80px;
                @media(max-width: $md1+px) {
                    height: 70px;
                }
                @media(max-width: $md6+px) {
                    height: 50px;
                }
            }
            .text {
                margin-top: 30px;
                @media(max-width: $md3+px) {
                    margin-top: 15px;
                }
            }
        }
    }
}