@import "../../../styles/_var.scss";
.practice {
    position: relative;
    z-index: 2;
}
.practiceBody {
    margin: 100px 0;
    @media (max-width: $md3+px) {
        margin: 40px 0;
    }
    @media (max-width: $md4+px) {
        margin: 60px 0;
    }
    @media (max-width: $md5+px) {
        margin: 80px 0;
    }
    @media (max-width: $md6+px) {
        margin: 100px 0;
    }
}
.practiceRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.practiceRowLeft {
    flex: 0 1 661px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
    }
}
.practiceRowLeftTitle {
    margin-bottom: 30px;
    text-align: left !important;
}

.practiceRowLeftSubtitle {
    margin-bottom: 20px;
    font-size: 20px !important;
    text-align: left !important;
    span {
        display: block;
    }

    @media (max-width: $md5+px) {
        font-size: 18px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.practiceRowLeftStart {
    margin-top: 50px;
    @media (max-width: $md4+px) {
        margin-top: 40px;
    }
    @media (max-width: $md5+px) {
        margin-top: 30px;
    }
    @media (max-width: $md6+px) {
        margin-top: 20px;
    }
}
.practiceRowRight {
    position: relative;
    top: 70px;
    @media (max-width: $md1+px) {
        flex: 0 1 500px;
        img {
            width: 100%;
        }
    }
    @media (max-width: $md3+px) {
        flex: 0 1 430px;
    }
    @media (max-width: $md4+px) {
        order: 2;
    }
    @media (max-width: $md6+px) {
        flex: 0 1 90%;
    }
}
