@import "../../../styles/_var.scss";
.faq {
    position: relative;
    z-index: 2;
    margin: 100px 0 0 0;
}
.faqBody {
}
.faqTitle {
    margin-bottom: 50px;
}

.faqWrapper {
    width: 1070px;
    margin: 0 auto;
    @media (max-width: $md3+px) {
        width: 100%;
    }
}
