@import '../../../styles/var';

.manageWallet {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    @media(max-width: $md3+px) {
        display: block;
    }
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        font-weight: 800;
        font-size: 35px;
        line-height: 150%;
        color: #57E9EB;
        @media(max-width: $md1+px) {
            font-size: 28px;
        }
        @media(max-width: $md6+px) {
            font-size: 25px;
            line-height: 38px;
        }
    }
    .text {
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        color: #FFFFFF;
        margin-top: 30px;
        @media(max-width: $md1+px) {
            font-size: 18px;
        }
        @media(max-width: $md6+px) {
            font-size: 13px;
            line-height: 20px;
        }
        .par {
            margin-top: 15px;
        }
    }
    .left {
        width: 646px;
        @media(max-width: $md1+px) {
            width: 580px;
        }
        @media(max-width: $md3+px) {
            width: 100%;
        }
    }
    .right {
        width: 614px;
        @media(max-width: $md1+px) {
            width: 500px;
        }
        @media(max-width: $md3+px) {
            width: 100%;
            margin-top: 30px;
        }
    }
}