@import "../../../styles/var";

.alwaysGiving {
    padding-top: 100px;
    @media (max-width: $md6+px) {
        padding-top: 50px;
    }
    .header {
        text-align: center;
        @media (max-width: $md6+px) {
        }
    }
    .text {
        text-align: center;
        width: 900px;
        margin: 30px auto 50px auto;
        @media (max-width: $md1+px) {
            width: 730px;
        }
        @media (max-width: $md5+px) {
            width: 100%;
        }
    }
    .cards {
        display: flex;
        justify-content: space-evenly;
        @media (max-width: $md4+px) {
            display: block;
        }
        .card {
            display: flex;
            flex-direction: column;
            width: 305px;
            @media (max-width: $md4+px) {
                margin: 0 auto 60px auto;
                width: 370px;
            }
            @media (max-width: $md6+px) {
                width: 210px;
            }
            &:nth-child(1) {
                .cardDesc {
                    width: 280px;
                    @media (max-width: $md1+px) {
                        width: 260px;
                    }
                    @media (max-width: $md4+px) {
                        width: unset;
                    }
                }
            }
            &Title {
                text-align: center;
                font-weight: 800;
                font-size: 24px;
                line-height: 38px;
                text-align: center;
                color: #57e9eb;
                @media (max-width: $md1+px) {
                    font-size: 20px;
                    line-height: 150%;
                    width: 280px;
                    margin: 0 auto;
                }
                @media (max-width: $md6+px) {
                    font-size: 18px;
                    line-height: 27px;
                    width: 100%;
                }
            }
            &Desc {
                text-align: center;
                font-weight: 300;
                font-size: 20px;
                line-height: 150%;
                text-align: center;
                color: #ffffff;
                margin: 30px 0 50px 0;
                flex: 1 1 auto;
                @media (max-width: $md1+px) {
                    font-size: 18px;
                    width: 280px;
                    margin: 20px auto 40px auto;
                }
                @media (max-width: $md4+px) {
                    width: unset;
                    margin: 15px auto 30px auto;
                }
                @media (max-width: $md6+px) {
                    font-size: 13px;
                    line-height: 20px;
                }
            }
            &Btn {
                text-align: center;
            }
        }
    }
}
