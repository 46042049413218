@import "./_var.scss";
body,
html {
    background-color: #000000;
    color: #ffffff;
    overflow-x: hidden;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}


.preloader {
    position: fixed;
    z-index: 999999;
    background-color: #0f2a2b;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    top: 0;
    left: 0;
    svg {
        margin-left: calc(50vw - 50px);
        margin-top: calc(50vh - 100px);
    }
}


.header-default {
    font-size: 40px;
    line-height: 150%;
    font-weight: 800;
    color: #57e9eb;
    @media (max-width: $md1+px) {
        font-size: 32px;
    }
    @media (max-width: $md6+px) {
        font-size: 25px;
    }
}
.text-default {
    font-weight: 300;
    font-size: 25px;
    line-height: 150%;
    color: #ffffff;
    @media (max-width: $md1+px) {
        font-size: 20px;
    }
    @media (max-width: $md6+px) {
        font-size: 13px;
        line-height: 20px;
    }
}

.relative {
    position: relative;
}
.z-1 {
    z-index: 1;
}

.hover-coin-img-1 {
    transform: translate(20px, 50px) !important;
}

.hover-coin-img-2 {
    transform: translateX(20px) !important;
}

.hover-coin-img-3 {
    transform: translate(-50px, 10px) !important;
}

.hover-coin-img-4 {
    transform: translate(20px, -50px) !important;
}

.header__bg {
    position: relative;
    background-image: url("../assets/img/MainPage/banner-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.main__bg {
    background-image: url("../assets/img/MainPage/main-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

:root {
    --card-height: 65vh;
    --card-width: calc(var(--card-height) / 1.5);
}

.title {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1;
    color: #57e9eb;
    text-align: center;
    @media (max-width: $md4+px) {
        font-size: 35px;
    }
    @media (max-width: $md5+px) {
        font-size: 30px !important;
    }
    @media (max-width: $md6+px) {
        font-size: 25px !important;
    }
}
.subtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 25px;
    line-height: 1.3;
    text-align: center;
    color: #ffffff;
    @media (max-width: $md4+px) {
        font-size: 22px;
    }
    @media (max-width: $md5+px) {
        font-size: 18px !important;
    }
    @media (max-width: $md6+px) {
        font-size: 14px !important;
    }
}
