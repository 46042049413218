@import '../../../styles/var';

.rangeTradingRow {
  display: flex;
  flex-wrap: wrap;
  .rangeTradingCol6 {
    flex: 0 0 auto;
    width: 50%;
    @media(max-width: $md5+px) {
      width: 100%;
    }
    .rangeTradingWrap {
      min-height: 340px;
      @media(max-width: $md5+px) {
        min-height: auto;
      }
      .rangeTradingTitle {
        font-size: 30px;
        @media(max-width: $md5+px) {
          font-size: 18px;
          margin-bottom: 30px;
        }
      }
      .rangeTradingText {
        @media(max-width: $md5+px) {
          font-size: 14px;
        }
      }
    }
    .rangeTradingNav {
      display: flex;
      margin-top: 30px;
      @media(max-width: $md5+px) {
        justify-content: center;
      }
      li {
        font-size: 35px;
        font-weight: 800;
        cursor: pointer;
        @media(max-width: $md5+px) {
          font-size: 25px;
        }
        &:not(:last-child) {
          margin-right: 65px;
          @media(max-width: $md5+px) {
            margin-right: 40px;
          }
        }
        &.active {
          color: #57E9EB;
        }
      }
    }
    .imgFluid {
      display: flex;
      margin: auto;
      @media(max-width: $md5+px) {
        display: none;
      }
    }
  }
}
