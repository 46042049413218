@import '../../../styles/var';

.chooseProminentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto;
  max-width: 770px;
  .chooseProminentTitle {
    font-size: 50px;
    text-align: center;
    @media (max-width: $md3+px) {
      font-size: 25px;
    }
  }
  .chooseProminentText {
    font-size: 25px;
    text-align: center;
    @media (max-width: $md3+px) {
      font-size: 13px;
    }
  }
  .chooseProminentLink {
    margin-top: 65px;
    @media (max-width: $md3+px) {
      margin-top: 25px;
    }
  }
}
