@import "../../../styles/_var.scss";
.first {
    position: relative;
    z-index: 2;

    height: 100vh;
    background: url("../../../assets/img/accounts/1/1.png") 0 bottom / cover no-repeat;
    @media (max-width: $md4+px) {
        height: auto;
    }
    @media (max-width: 600px) {
        background: url("../../../assets/img/accounts/1/1mob.png") 0 bottom / 100% 364px no-repeat;
    }
}
.firstBody {
}

.firstTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.5;
    color: #57e9eb;
    margin-bottom: 50px;
    @media (max-width: $md1+px) {
        font-size: 50px;
    }
    @media (max-width: $md2+px) {
        font-size: 45px;
    }
    @media (max-width: $md3+px) {
        font-size: 40px;
    }
    @media (max-width: $md4+px) {
        font-size: 35px;
    }
    @media (max-width: $md5+px) {
        font-size: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}
.firstStart {
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}

.firstRow {
    display: flex;
    padding-top: 200px;
    justify-content: space-between;
    @media (max-width: $md1+px) {
        padding-top: 180px;
    }
    @media (max-width: $md2+px) {
        padding-top: 150px;
    }
    @media (max-width: $md3+px) {
        padding-top: 120px;
        padding-bottom: 60px;
    }
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    @media (max-width: 600px) {
        justify-content: flex-start;
    }
}
.firstRowLeft {
    flex: 0 1 900px;
    @media (max-width: $md3+px) {
        flex: 0 1 1000px;
    }
}
.firstRowRight {
    flex: 0 1 696px;
    position: relative;
    top: 30px;
    @media (max-width: $md1+px) {
        top: 0;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 400px;
    }
    @media (max-width: 840px) {
        margin-right: 100px;
    }
    @media (max-width: 600px) {
        margin-right: 0;
        flex: 0 1 100%;
    }
    transition: all 0.3s linear;
    &.active {
        top: -50px;
    }
    img {
        @media (max-width: 600px) {
            width: 400px;
        }
        @media (max-width: 500px) {
            width: 300px;
        }
        @media (max-width: 400px) {
            width: 270px;
        }
        width: 100%;
    }
}
