@import '../../styles/var';

.pageBG {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100% !important;
    width: 100% !important;
    background: url('../../assets/img/AboutPage/bg_page.png') 0 0 / cover no-repeat !important;
}