@import '../../styles/var';

.bannerBG {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100% !important;
    width: 100% !important;
    background: url('../../assets/img/StartPage/bg_banner.png') 0 100% / cover no-repeat !important;
    @media(max-width: $md4+px) {
        background-position: bottom right!important;
    }
    @media(max-width: $md6+px) {
        background-image: url('../../assets/img/StartPage/bg_banner_mobile.png')!important;
    }
}
.pageBG {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100% !important;
    width: 100% !important;
    background: url('../../assets/img/StartPage/bg_page.png') 0 0 / cover no-repeat !important;
}