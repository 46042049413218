@import "../../../styles/_var.scss";
.spoiler {
    border: 2px solid #1ab7b1;
    // box-shadow: 8px 7px 21px rgba(0, 0, 0, 0.15), -6px -7px 11px rgba(255, 255, 255, 0.84);

    width: 100%;
    padding: 11px 30px;
    margin-bottom: 40px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    @media (max-width: $md4+px) {
        padding: 11px 20px;
        margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
        padding: 11px 10px;
        margin-bottom: 20px;
    }
    transition: all 0.3s linear;
    &.active {
        .spoilerContent {
            &:nth-child(2) {
                margin-top: 30px;
            }
            opacity: 1;
            margin-top: 10px;
            font-size: 20px;
            @media (max-width: $md5+px) {
                font-size: 18px;
            }
            @media (max-width: $md5+px) {
                font-size: 16px;
            }
            @media (max-width: $md5+px) {
                font-size: 14px;
                margin-top: 20px;
            }
            @media (max-width: $md6+px) {
                font-size: 12px;
                margin-top: 10px;
            }
            &List {
                margin-top: 5px;
                display: list-item;
                list-style-position: outside;
                margin-left: 30px;
                padding-right: 30px;
                &::marker {
                    padding-left: 50px;
                }
            }
        }
        .listMargin {
            margin-top: 10px;
        }
        .spoilerTitle {
            &:before {
                transform: rotate(90deg);
            }
        }
    }
}
.spoilerTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 25px;
    color: #57e9eb;
    position: relative;
    padding-right: 30px;
    @media (max-width: $md4+px) {
        font-size: 22px;
    }
    @media (max-width: $md5+px) {
        font-size: 19px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: 540px) {
        font-size: 14px;
    }
    @media (max-width: $md6+px) {
        padding-right: 30px;
    }
    &:after {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: #57e9eb;
        @media (max-width: $md6+px) {
            width: 12px;
            height: 1px;
        }
    }
    &:before {
        content: "";
        display: "inline-block";
        right: 9px;
        top: 0px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 2px;
        height: 20px;
        background-color: #57e9eb;
        transition: all 0.3s linear;
        @media (max-width: $md6+px) {
            width: 1px;
            height: 12px;
            right: 5px;
        }
    }
}
.spoilerContent {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 1.3;
    color: #ffffff;
    position: relative;
    transition: all 0.3s linear;
    opacity: 0;
    font-size: 0;

    margin-top: 0px;
    max-width: 900px;
}
