@import '../../../styles/var';

.bannerStart {
    position: relative;
    padding-top: 180px;
    @media(max-width: $md5+px) {
        padding: 100px 0 0 0;
    }
    @media(max-width: $md6+px) {
        padding: 100px 0 0 0;
    }
    .header {
        font-weight: 800;
        font-size: 60px;
        line-height: 150%;
        color: #57E9EB;
        width: 900px;
        @media(max-width: $md1+px) {
            font-size: 50px;
        }
        @media(max-width: $md3+px) {
            width: 500px;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        @media(max-width: $md6+px) {
            font-size: 25px;
            line-height: 38px;
        }
    }
    .text {
        margin: 30px 0 50px 0;
        width: 793px;
        font-weight: 300;
        font-size: 30px;
        line-height: 150%;
        @media(max-width: $md1+px) {
            font-size: 24px;
            width: 650px;
        }
        @media(max-width: $md3+px) {
            width: 480px;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        @media(max-width: $md5+px) {
            margin: 15px 0;
        }
        @media(max-width: $md6+px) {
            font-size: 15px;
            line-height: 22px;
        }
    }
    .btn {

    }
    .image {
        position: absolute;
        top: 150px;
        right: 0;
        width: 641px;
        @media(max-width: $md1+px) {
            width: 500px;
            top: 170px;
        }
        @media(max-width: $md3+px) {
            top: 180px;
            width: 450px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            margin: 50px auto 0 auto;
            width: 500px;
        }
        @media(max-width: $md6+px) {
            width: 100%;
        }
    }
}