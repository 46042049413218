@import "../../../styles/var";

.readyTo {
    margin-top: 100px;
    @media (max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
    }
    .btns {
        margin-top: 43px;
        display: flex;
        gap: 150px;
        justify-content: center;
        @media (max-width: $md4+px) {
            gap: 50px;
        }
        @media (max-width: $md3+px) {
            flex-wrap: wrap;
            gap: 0px;
            & > a {
                width: 100%;
                text-align: center;
                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }
        @media (max-width: $md5+px) {
            display: grid;
            gap: 0px;
            text-align: center;
        }
    }
}
