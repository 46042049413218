@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}


