@import "../../../styles/_var.scss";
.education {
    margin: 150px 0 100px 0;
    position: relative;
    z-index: 2;
    @media (max-width: $md4+px) {
        margin: 120px 0 80px 0;
    }
    @media (max-width: $md5+px) {
        margin: 90px 0 60px 0;
    }
    @media (max-width: $md6+px) {
        margin: 60px 0 30px 0;
    }
}
.educationBody {
}
.educationTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.3;
    color: #57e9eb;
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: $md1+px) {
        font-size: 50px;
    }
    @media (max-width: $md2+px) {
        font-size: 45px;
    }
    @media (max-width: $md3+px) {
        font-size: 40px;
    }
    @media (max-width: $md4+px) {
        font-size: 35px;
    }
    @media (max-width: $md5+px) {
        font-size: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 25px;
    }
}

.educationSubtitle {
    max-width: 567px;
    margin: 0 auto 50px auto;
    @media (max-width: $md3+px) {
        margin: 0 auto 45px auto;
    }
    @media (max-width: $md4+px) {
        margin: 0 auto 40px auto;
    }
    @media (max-width: $md5+px) {
        margin: 0 auto 35px auto;
    }
    @media (max-width: $md6+px) {
        margin: 0 auto 30px auto;
    }
}
.educationStart {
    display: flex;
    justify-content: center;
}
