.education {
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        display: block;
        left: 0;
        z-index: 1;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../assets/img/education/bg.png") 0 0 / cover no-repeat;
    }
}
