@import "../../../styles/var";
a {
    color: #fff;
}
.footer {
    margin-top: 120px;
    border-top: 2px solid white;
    padding: 27px 0;
    display: flex;
    gap: 124px;
    position: relative;
    z-index: 3;
    @media (max-width: $md1+px) {
        gap: 70px;
    }
    @media (max-width: $md3+px) {
        display: grid;
        grid-template: repeat(2, 0fr) / repeat(2, 1fr);
        gap: 30px 0;
    }
    @media (max-width: $md6+px) {
        margin-top: 70px;
        gap: 15px 0;
        padding: 21px 0 8px 0;
        border-top: 1px solid white;
    }
    .col {
        &_01 {
            font-weight: 400;
            font-size: 9px;
            line-height: 14px;
            color: white;
            width: 895px;
            @media (max-width: $md1+px) {
                width: 700px;
            }
            @media (max-width: $md2+px) {
                width: 600px;
            }
            @media (max-width: $md3+px) {
                grid-row-start: 2;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 3;
                width: unset;
            }
            .header {
                font-weight: 700;
            }
            .row {
                &:nth-child(2),
                &:nth-child(3) {
                    margin-top: 15px;
                }
            }
        }
        &_02 {
            width: 157px;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            @media (max-width: $md3+px) {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 1;
                grid-column-end: 1;
                width: unset;
            }
            @media (max-width: $md6+px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }
            & > div {
                cursor: pointer;
            }
            .row {
                margin-top: 20px;
                @media (max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
        &_03 {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: white;
            width: 315px;
            @media (max-width: $md3+px) {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 2;
                grid-column-end: 2;
                width: unset;
            }
            @media (max-width: $md6+px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }
            .row {
                margin-top: 30px;
                @media (max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
    }
}
