@import '../../../styles/var';

.tradeCFDContainer {
  position: relative;
  background-image: url("../../../assets/img/MainPage/trade-cfd-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media(max-width: $md5+px) {
    background-image: url("../../../assets/img/MainPage/trade-cfd-mobile-bg.png");
  }
  .tradeCFDWrap {
    max-width: 840px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 35px 0;
    .tradeCFDTitle {
      font-size: 50px;
      text-align: center;
      margin-bottom: 30px;
    }
    .tradeCFDText {
      text-align: center;
      font-size: 20px;
      margin-bottom: 15px;
      max-width: 700px;
    }
    .tradeCFDLink {
      margin-top: 35px;
    }
  }
  @for $i from 1 through 6 {
    .tradeCfdIcon#{$i} {
      position: absolute;
    }
    @if($i == 1) {
      .tradeCfdIcon#{$i} {
        top: 40px;
        left: 115px;
      }
    }
    @else if($i == 2) {
      .tradeCfdIcon#{$i} {
        top: 150px;
        left: 400px;
      }
    }
    @else if($i == 3) {
      .tradeCfdIcon#{$i} {
        bottom: 75px;
        left: 500px;
      }
    }
    @else if($i == 4) {
      .tradeCfdIcon#{$i} {
        bottom: 145px;
        right: 425px;
      }
    }
    @else if($i == 5) {
      .tradeCfdIcon#{$i} {
        top: 60px;
        right: 300px;
      }
    }
    @else {
      .tradeCfdIcon#{$i} {
        top: 150px;
        right: 85px;
      }
    }
  }
}
