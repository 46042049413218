@import '../../../styles/var';

.quickFacts {
    margin-top: 130px;
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
        @media(max-width: $md5+px) {
            width: calc(100% - 100px);
            margin: 0 auto;
            text-align: left;
        }
        @media(max-width: $md6+px) {
            width: calc(100% - 20px);
        }
    }
    .content {
        margin-top: 90px;
        position: relative;
        @media(max-width: $md6+px) {
            margin-top: 80px;
        }
        .plate {
            position: relative;
            z-index: 3;
            margin-top: 30px;
            width: 1250px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) -30.29%, rgba(255, 255, 255, 0) 144.92%);
            backdrop-filter: blur(25px);
            border: 1px solid rgba(0, 221, 156, 0.1);
            display: flex;
            padding: 10px 0;
            @media(max-width: $md1+px) {
                width: 1000px;
            }
            @media(max-width: $md2+px) {
                width: 900px;
            }
            @media(max-width: $md4+px) {
                width: calc(100% - 50px);
                margin-top: 20px;
            }
            @media(max-width: $md5+px) {
                width: calc(100% - 100px);
                background: unset;
                padding: unset;
                backdrop-filter: unset;
                display: block;
                border: unset;
                margin: 0 auto 30px auto;
            }
            @media(max-width: $md6+px) {
                width: calc(100% - 20px);
            }
            &:hover {
                .num {
                    -webkit-text-fill-color: rgba(87,233,235, 0.5);
                }
            }
            .num {
                position: relative;
                z-index: 4;
                font-weight: 700;
                font-size: 100px;
                line-height: 150%;
                display: flex;
                align-items: center;
                -webkit-text-stroke: 1px #57E9EB;
                -webkit-text-fill-color: transparent;
                @media(max-width: $md1+px) {
                    font-size: 90px;
                }
                @media(max-width: $md5+px) {
                    display: block;
                    text-align: center;
                    margin: 0 0 0 0!important;
                    font-size: 66.8852px;
                    line-height: 66px;
                }
            }
            .text {
                position: relative;
                z-index: 4;
                @media(max-width: $md5+px) {
                    width: 100%!important;
                    text-align: center!important;
                }
                .first {
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 20px;
                    color: #FFC045;
                    @media(max-width: $md1+px) {
                        font-size: 18px;
                        line-height: 18px;
                    }
                    @media(max-width: $md5+px) {
                        font-size: 15px;
                        line-height: 15px;
                        margin-top: 15px;
                    }
                }
                .second {
                    margin-top: 10px;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 32px;
                    color: #80AB9E;
                    @media(max-width: $md1+px) {
                        font-size: 14px;
                        line-height: 30px;
                    }
                    @media(max-width: $md5+px) {
                        font-size: 13px;
                        line-height: 32px;
                        margin-top: 15px;
                    }
                }
            }
            .light {
                position: absolute;
                z-index: 3;
                @media(max-width: $md5+px) {
                    display: none;
                }
                &_01 {
                    top: -70px;
                    left: -30px;
                }
                &_02 {
                    top: -35px;
                    right: 10px;
                }
                &_03 {
                    top: -60px;
                    left: -80px;
                }
                &_04 {
                    top: -20px;
                    left: 100px;
                    @media(max-width: $md1+px) {
                        left: 1%;
                    }
                }
                &_05 {
                    top: -30px;
                    right: 150px;
                    @media(max-width: $md1+px) {
                        right: 5%;
                    }
                }
                &_06 {
                    top: -40px;
                    right: 10px;
                }
            }
            .icon {
                position: absolute;
                z-index: 3;
                @media(max-width: $md2+px) {
                    display: none;
                }
                &_01 {
                    top: 10%;
                    right: 30%;
                    @media(max-width: $md1+px) {
                        right: 20%;
                    }
                }
                &_02 {
                    top: 25%;
                    left: 12%;
                    @media(max-width: $md1+px) {
                        left: 5%;
                    }
                }
                &_03 {
                    top: 20%;
                    right: 20%;
                    @media(max-width: $md1+px) {
                        right: 15%;
                    }
                }
                &_04 {
                    top: 20%;
                    left: 12%;
                    @media(max-width: $md1+px) {
                        left: 5%;
                    }
                }
                &_05 {
                    top: 10%;
                    right: 15%;
                    @media(max-width: $md1+px) {
                        right: 10%;
                    }
                }
                &_06 {
                    top: 25%;
                    left: 12%;
                    @media(max-width: $md1+px) {
                        left: 5%;
                    }
                }
            }
            &Left {
                border-top-right-radius: 100px;
                border-end-end-radius: 100px;
                border-left: none;
                justify-content: flex-end;
                text-align: right;
                @media(max-width: $md5+px) {
                    display: flex;
                    flex-direction: column-reverse;
                }
                .num {
                    margin: 0 63px 0 100px;
                    @media(max-width: $md1+px) {
                        margin: 0 63px 0 60px;
                    }
                }
            }
            &Right {
                border-top-left-radius: 100px;
                border-end-start-radius: 100px;
                border-right: none;
                margin-left: auto;
                .num {
                    margin: 0 100px 0 63px;
                    @media(max-width: $md1+px) {
                        margin: 0 60px 0 63px;
                    }
                }
            }
            &_01 {
                .text {
                    width: 450px;
                    padding-top: 30px;
                    @media(max-width: $md1+px) {
                        width: 420px;
                        padding-top: 26px;
                    }
                    @media(max-width: $md5+px) {
                        padding-top: 0;
                    }
                }
            }
            &_02 {
                .text {
                    width: 535px;
                    padding-top: 30px;
                    @media(max-width: $md1+px) {
                        width: 500px;
                        padding-top: 26px;
                    }
                    @media(max-width: $md5+px) {
                        padding-top: 0;
                    }
                }
            }
            &_03 {
                .text {
                    width: 490px;
                    padding-top: 30px;
                    @media(max-width: $md1+px) {
                        width: 450px;
                        padding-top: 26px;
                    }
                    @media(max-width: $md5+px) {
                        padding-top: 0;
                    }
                }
            }
            &_04 {
                .text {
                    width: 602px;
                    padding-top: 13px;
                    @media(max-width: $md1+px) {
                        width: 570px;
                        padding-top: 10px;
                    }
                    @media(max-width: $md5+px) {
                        padding-top: 0;
                    }
                }
            }
            &_05 {
                .text {
                    width: 668px;
                    padding-top: 13px;
                    @media(max-width: $md1+px) {
                        width: 500px;
                        padding-top: 10px;
                    }
                    @media(max-width: $md5+px) {
                        padding-top: 0;
                    }
                }
            }
            &_06 {
                .text {
                    width: 602px;
                    padding-top: 13px;
                    @media(max-width: $md1+px) {
                        width: 530px;
                        padding-top: 10px;
                    }
                    @media(max-width: $md5+px) {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}