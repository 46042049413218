@import "../../../styles/_var.scss";
.practice {
    position: relative;
    z-index: 2;
}
.practiceBody {
}
.practiceRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.practiceRowLeft {
    flex: 0 1 693px;
    @media (max-width: $md1+px) {
        flex: 0 1 650px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 40px;
    }
}
.practiceRowLeftTitle {
    margin-bottom: 30px;
    text-align: left !important;
}

.practiceRowLeftSubtitle {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: left !important;
    span {
        display: block;
    }

    @media (max-width: $md5+px) {
        font-size: 18px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.practiceRowLeftList {
    margin-bottom: 50px;
    p {
        font-size: 20px;
        text-align: left !important;
        position: relative;
        padding-left: 20px;
        margin-bottom: 15px;

        @media (max-width: $md5+px) {
            font-size: 18px;
        }
        @media (max-width: $md6+px) {
            font-size: 14px;
        }
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 9px;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #57e9eb;
            border-radius: 50%;
            @media (max-width: $md5+px) {
                top: 7px;
            }
            @media (max-width: $md6+px) {
                top: 5px;
            }
        }
    }
}
.practiceRowLeftStart {
}
.practiceRowRight {
    @media (max-width: $md1+px) {
        flex: 0 1 500px;
        img {
            width: 100%;
        }
    }
    @media (max-width: $md3+px) {
        flex: 0 1 430px;
    }
}
