@import "../../../styles/var";

.ourAdvantagesSection {
  background-image: url("../../../assets/img/MainPage/our-advantages-bg.jpg");
  background-position: center;
  background-size: cover;
  .ourAdvantagesRow {
    display: flex;
    flex-wrap: wrap;
    .ourAdvantagesCol6 {
      position: relative;
      flex: 0 0 auto;
      width: 50%;
      padding: 100px 0;
      @media (max-width: $md5+px) {
        width: 100%;
        padding: 60px 0;
      }
      &:last-child {
        @media (max-width: $md5+px) {
          display: none;
        }
      }
      .ourAdvantagesWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .ourAdvantagesTitle {
          margin-bottom: 50px;
        }
        .ourAdvantagesList {
          position: relative;
          z-index: 99;
          .ourAdvantagesItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 20px;
            font-weight: 300;
            text-align: center;

            margin-bottom: 20px;
            max-width: 305px;
            @media (max-width: $md5+px) {
              font-size: 13px;
            }
            &:before {
              content: "";
              display: flex;
              justify-content: center;
              align-items: center;
              width: 11px;
              height: 11px;
              margin-bottom: 20px;
              background-color: #57e9eb;
              border-radius: 50%;
            }
          }
        }
      }
      .imageWrap {
        display: flex;
        justify-content: center;
        .imgFluid {
          max-width: 370px;
          height: auto;
        }
      }
    }
  }
}
