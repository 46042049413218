@import "../../../styles/var";

.btnDefault {
    position: relative;
    display: inline-flex;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;
    color: #003031;
    border-radius: 43px;

    padding: 2px;
    @media (max-width: $md5+px) {
        font-size: 18px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
        line-height: 31px;
        padding: 1px;
    }
    background: linear-gradient(90deg, #d8feff 4.09%, #2a8488 99.85%);
    &:hover {
        background: linear-gradient(90deg, #d8feff 30%, #2a8488 99%);
    }
    div {
        transition: all 0.5s;
        padding: 0px 15px;
        height: 100%;
        width: 100%;
        border-radius: 43px;
        border: 3px solid;
    }
}
