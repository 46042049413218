@import '../../../styles/var';

.topReasonsSection {
  padding: 100px 0;

  @media(max-width: $md5+px) {
    padding: 50px 0;
  }
  .topReasonsRow {
    display: flex;
    flex-wrap: wrap;
    .topReasonsCol6 {
      flex: 0 0 auto;
      width: 50%;
      @media(max-width: $md5+px) {
        width: 100%;
      }
      .topReasonsWrap {
        .topReasonsTitle {
          font-size: 50px;
          margin-bottom: 40px;
          @media(max-width: $md5+px) {
            text-align: center;
            font-size: 25px;
            margin-bottom: 30px;
          }
        }
        .topReasonsSubTitle {
          font-size: 40px;
          color: #57E9EB;
          font-weight: 600;
          margin-bottom: 15px;
          @media(max-width: $md5+px) {
            font-size: 18px;
          }
        }
        .topReasonsText {
          font-size: 20px;
          font-weight: 300;
          margin-bottom: 25px;
          max-width: 340px;
          @media(max-width: $md5+px) {
            font-size: 13px;
            margin-bottom: 20px;
          }
          &.topReasonsLastText {
            max-width: 645px;
            margin-bottom: 50px;
            @media(max-width: $md5+px) {
              margin-bottom: 30px;
            }
          }
        }
      }
      .imgFluid {
        max-width: 100%;
        height: auto;
        @media(max-width: $md5+px) {
          margin-top: 50px;
        }
      }
    }
    .topReasonsCol4 {
      flex: 0 0 auto;
      width: 33.333333%;
      margin-top: 100px;
      @media(max-width: $md5+px) {
        width: 100%;
      }
      .topReasonsWrap {
        display: flex;
        justify-content: center;
        @media(max-width: $md5+px) {
          flex-direction: column;
          align-items: center;
        }
        .topReasonsNumber {
          font-size: 100px;
          font-weight: 600;
          .imgFluid {
            max-width: 100%;
            height: auto;
            @media(max-width: $md5+px) {
              width: 90px;
            }
          }
        }
        .topReasonsNumberText {
          display: flex;
          align-items: center;
          font-size: 30px;
          font-weight: 600;
          @media(max-width: $md5+px) {
            font-size: 21px;
            text-align: center;
          }
        }
      }
    }
  }
}
