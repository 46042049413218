@import '../../../styles/var';

.trustedPartner {
    margin-top: 100px;
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
    }
    .text {
        text-align: center;
        width: 675px;
        margin: 30px auto 50px auto;
        @media(max-width: $md1+px) {
            width: 580px;
        }
        @media(max-width: $md5+px) {
            width: 90%;
        }
    }
    .cards {
        display: flex;
        justify-content: space-around;
        @media(max-width: $md4+px) {
            display: grid;
            grid-template: repeat(2,1fr)/repeat(2,0fr);
            gap: 40px 0;
        }
        .card {
            width: 244px;
            text-align: center;
            position: relative;
            @media(max-width: $md1+px) {
                width: 200px;
            }
            &:hover {
                .cardIcon {
                    scale: 1.05;
                }
            }
            &Icon {
                transition: all .3s;
                @media(max-width: $md1+px) {
                    height: 90px;
                }
                @media(max-width: $md6+px) {
                    height: 67px;
                }
            }
            &Text {
                position: relative;
                margin-top: 30px;
                @media(max-width: $md4+px) {
                    margin-top: 17px;
                }
            }
        }
    }
    .btn {
        margin-top: 50px;
        text-align: center;
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
    }
}