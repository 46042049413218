@import '../../../styles/var';

.whatEnergizes {
    margin-top: 60px;
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
    }
    .cards {
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        @media(max-width: $md3+px) {
            display: grid;
            grid-template: repeat(2,1fr)/repeat(3,1fr);
            gap: 40px 0;
        }
        @media(max-width: $md5+px) {
        }
        .card {
            text-align: center;
            &:hover {
                .icon {
                    scale: 1.05;
                }
            }
            .icon {
                transition: all .3s;
                height: 80px;
                @media(max-width: $md1+px) {
                    height: 70px;
                }
                @media(max-width: $md6+px) {
                    height: 50px;
                }
            }
            .text {
                margin-top: 30px;
                @media(max-width: $md3+px) {
                    margin-top: 15px;
                }
            }
        }
    }
}