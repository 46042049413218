@import "../../../styles/_var.scss";
.what {
    position: relative;
    z-index: 2;
}
.whatBody {
}
.whatRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.whatRowLeft {
    flex: 0 1 660px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-top: 50px;
        order: 2;
    }
}
.whatRowLeftTitle {
    text-align: left !important;
    margin-bottom: 15px;
}

.whatRowLeftSubtitle {
    margin-bottom: 20px !important;
    font-size: 20px !important;
    text-align: left !important;

    @media (max-width: $md5+px) {
        font-size: 18px !important;
    }
    @media (max-width: $md6+px) {
        font-size: 14px !important;
    }
}
.whatRowLeftWhite {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.3;
    color: #ffffff;
    margin-bottom: 30px;
    @media (max-width: $md4+px) {
        font-size: 17px;
    }
    @media (max-width: $md5+px) {
        font-size: 15px;
    }
    @media (max-width: $md6+px) {
        font-size: 13px;
    }
}
.whatRowLeftList {
    p {
        font-size: 20px;
        text-align: left !important;
        position: relative;
        padding-left: 20px;
        margin-bottom: 15px;

        @media (max-width: $md5+px) {
            font-size: 18px;
        }
        @media (max-width: $md6+px) {
            font-size: 14px;
        }
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 9px;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #fff;
            border-radius: 50%;
            @media (max-width: $md5+px) {
                top: 7px;
            }
            @media (max-width: $md6+px) {
                top: 5px;
            }
        }
    }
}
.whatRowRight {
    position: relative;
    @media (max-width: $md1+px) {
        flex: 0 1 40%;
        img {
            width: 100%;
        }
    }
    @media (max-width: $md4+px) {
        transform: scale(1.4);
        margin-bottom: 50px;
    }
    @media (max-width: $md5+px) {
        max-width: 307px;
        width: 307px;
        min-width: 307px;
        transform: scale(1.2);
    }
    @media (max-width: $md6+px) {
        transform: scale(1);
        margin-bottom: 20px;
    }
}
.whatRowRightImg {
    position: relative;
    bottom: -60px;
    @media (max-width: $md1+px) {
        bottom: -30px;
    }
}
.whatRowRightImgTop {
    position: absolute;
    left: 320px;
    top: -10px;
    @media (max-width: $md1+px) {
        left: 175px;
        width: 180px;
        top: -50px;
        img {
            width: 100%;
        }
    }
    @media (max-width: $md2+px) {
        left: 155px;
    }
    @media (max-width: $md3+px) {
        width: 150px;
        left: 130px;
    }
    @media (max-width: $md4+px) {
        width: 100px;
        left: 105px;
        top: 55px;
    }
}
