@import "../../../styles/_var.scss";
.columns {
    position: relative;
    z-index: 2;
}
.columnsBody {
    margin: 180px 0 100px 0;
    @media (max-width: $md2+px) {
        margin: 130px 0 90px 0;
    }
    @media (max-width: $md3+px) {
        margin: 110px 0 80px 0;
    }
    @media (max-width: $md4+px) {
        margin: 90px 0 70px 0;
    }
    @media (max-width: $md5+px) {
        margin: 70px 0 60px 0;
    }
    @media (max-width: $md6+px) {
        margin: 50px 0 50px 0;
    }
    @media (max-width: $md5+px) {
        & > div {
            padding: 0 0 0 10px !important;
        }
    }
}
.columnsRowWrapper {
    @media (max-width: $md4+px) {
        overflow-y: hidden;
    }
}
.columnsRow {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 1260px;
    margin: 0 auto;
    @media (max-width: $md1+px) {
        left: 20px;
    }
    @media (max-width: $md2+px) {
        width: 950px;
    }
    @media (max-width: $md3+px) {
        left: 30px;
    }
    @media (max-width: $md4+px) {
        left: 0;
        width: 950px;
        padding: 0px;
        margin-bottom: 30px;
    }
}
.columnsRowColumn {
    width: 380px;

    position: relative;

    &:nth-child(2) {
        &:after {
            background: url("../../../assets/img/accounts/2/2.png") 0 0 no-repeat;
        }
    }
    &:nth-child(3) {
        &:after {
            background: url("../../../assets/img/accounts/2/3.png") 0 0 no-repeat;
        }
    }
    .columnsRowColumnItem {
        display: flex;
        flex-direction: column;
    }
    &:hover {
        &:after {
            filter: blur(3px);
        }
    }

    .columnsRowColumnItemTitle {
        opacity: 1;
        flex: 0 1 70px;
    }
    .columnsRowColumnItemBlock {
        opacity: 1;
    }

    @media (max-width: $md4+px) {
        .columnsRowColumnItemTitle {
            opacity: 1;
        }
        .columnsRowColumnItemBlock {
            opacity: 1;
        }
        &:after {
            filter: blur(3px);
        }
    }
    &:after {
        content: "";
        position: absolute;
        width: 351px;
        height: 542px;
        position: absolute;
        left: 0;
        top: 0;
        background: url("../../../assets/img/accounts/2/1.png") 0 0 no-repeat;
        pointer-events: none;
        transition: all 0.3s linear;
        @media (max-width: $md2+px) {
            width: 258px;
            height: 400px;
            background-size: cover !important;
        }
    }
    &:before {
        content: "";
        position: absolute;
        width: 351px;
        height: 542px;
        position: absolute;
        left: 0;
        top: 0;
        background: #0c2733;
        pointer-events: none;
        @media (max-width: $md2+px) {
            width: 258px;
            height: 400px;
        }
    }
}
.columnsRowColumnItem {
    padding: 0px 0 0 0;
}
.columnsRowColumnItemTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 1;
    color: #57e9eb;

    position: relative;
    z-index: 2;
    transition: all 0.3s linear;
    opacity: 0;
    @media (max-width: $md2+px) {
        font-size: 22px;
    }
}
.columnsRowColumnItemBlock {
    margin-bottom: 25px;
    position: relative;
    z-index: 2;
    transition: all 0.3s linear;
    opacity: 0;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: $md2+px) {
        margin-bottom: 15px;
    }
}
.columnsRowColumnItemBlockTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 10px;
    @media (max-width: $md2+px) {
        font-size: 14px;
        margin-bottom: 5px;
    }
}
.columnsRowColumnItemBlockSubtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.1;
    color: #ffffff;
    margin-bottom: 5px;
    @media (max-width: $md2+px) {
        font-size: 14px;
    }
}
.columnsRowColumnOpenBtn {
    display: flex;
    justify-content: center;
    position: relative;
    left: -2%;
    z-index: 10;
    margin-top: 45px;
    @media (max-width: $md2+px) {
        left: -7%;
        margin-top: 35px;
        & > a {
            width: 195px;
            height: 45px;
            text-align: center;
            div {
                font-size: 19px !important;
                padding: 6px 10px;
                line-height: 19px;
            }
        }
    }
    @media (max-width: $md4+px) {
        left: -9%;
    }
    @media (max-width: $md6+px) {
        & > a {
            div {
                line-height: 25px !important;
            }
        }
    }
}

@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

.columnsRowColumnItem {
    width: var(--card-width);
    height: var(--card-height);
    width: 350px;
    height: 542px;
    position: relative;
    @media (max-width: $md4+px) {
        overflow: hidden;
    }
    justify-content: center;
    align-items: center;
    text-align: center;

    color: rgb(88 199 250 / 0%);
    cursor: pointer;
    @media (max-width: $md2+px) {
        width: 258px;
        height: 400px;
    }
}
.columnsRowColumnItemLine {
    background-color: transparent;
    height: 150px;
    width: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    bottom: -150px;
}
.columnsRowColumnItem:hover {
    color: rgb(88 199 250 / 100%);
    transition: color 1s;
}
.columnsRowColumnItem:hover:before,
.columnsRowColumnItem:hover:after {
    animation: none;
    opacity: 0;
}
@media (max-width: $md4+px) {
    .columnsRowColumnItem:before,
    .columnsRowColumnItem:after {
        animation: none;
        opacity: 0;
    }
}

.columnsRowColumnItem::before {
    content: "";
    width: 104%;
    height: 102%;

    background-image: linear-gradient(var(--rotate), #54cebd, #2a8f82 43%, #086157);
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: spin 2.5s linear infinite;
}

.columnsRowColumnItem::after {
    position: absolute;
    content: "";
    top: calc(var(--card-height) / 6);
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.8);
    filter: blur(calc(var(--card-height) / 6));
    background-image: linear-gradient(var(--rotate), #54cebd, #2a8f82 43%, #086157);
    opacity: 1;
    transition: opacity 0.5s;
    animation: spin 2.5s linear infinite;
}

@keyframes spin {
    0% {
        --rotate: 0deg;
    }
    100% {
        --rotate: 360deg;
    }
}
