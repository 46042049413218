@import "../../../styles/var";

.trustedCards {
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: $md6+px) {
        margin-top: 50px;
    }
    @media (max-width: $md4+px) {
        display: block;
    }
    .card {
        overflow: hidden;
        position: relative;
        height: 350px;
        width: 279px;
        border: 0.8px solid #33e9e9;
        @media (max-width: $md4+px) {
            margin: 0 auto 30px auto;
        }
        @media (max-width: $md6+px) {
            height: 262px;
            width: 209px;
        }
        &:hover {
            .icon {
                filter: blur(7.5px);
                background-blend-mode: overlay;
            }
            .content {
                top: 25px;
                @media (max-width: $md6+px) {
                    top: 20px;
                }
            }
        }
        .icon {
            transition: all 0.3s;
            width: 100%;
            position: absolute;
            bottom: 65px;
            z-index: 2;
            @media (max-width: $md6+px) {
                bottom: 45px;
                padding-bottom: 15px;
            }
        }
        .content {
            z-index: 4;
            position: absolute;
            top: 83%;
            transition: all 0.3s;
            .header {
                font-weight: 800;
                font-size: 18px;
                line-height: 30px;
                color: #57e9eb;
                text-align: left;
                padding: 0 20px;
                @media (max-width: $md6+px) {
                    font-size: 15px;
                    line-height: 22px;
                }
            }
            .text {
                margin-top: 25px;
                padding: 0 20px;
                font-weight: 300;
                font-size: 18px;
                line-height: 30px;
                color: #ffffff;
                @media (max-width: $md6+px) {
                    font-size: 14px;
                    line-height: 21px;
                    padding: 0 10px;
                    margin-top: 22px;
                }
            }
        }
    }
}
