@import "../../../styles/_var.scss";
.deposits {
    position: relative;
    z-index: 2;
}
.depositsBody {
    margin: 100px 0;
    @media (max-width: $md3+px) {
        margin: 60px 0;
    }
    @media (max-width: $md4+px) {
        margin: 70px 0;
    }
    @media (max-width: $md5+px) {
        margin: 60px 0;
    }
    @media (max-width: $md6+px) {
        margin: 50px 0;
    }
}
.depositsTitle {
    margin-bottom: 30px;
}
.depositsSubtitle {
    max-width: 880px;
    margin: 0 auto 50px auto;
}
.depositsWrapper {
    width: 1200px;
    margin: 0 auto;
    max-width: 1200px;
    @media (max-width: $md3+px) {
        width: 100%;
    }
    @media (max-width: $md4+px) {
        width: 992px;
        margin-bottom: 30px;
    }
}
.depositsWrapperSecond {
    overflow: auto;
    &::-webkit-scrollbar {
        height: 5px; /* ширина всей полосы прокрутки */
    }

    &::-webkit-scrollbar-track {
        background: #5b6764; /* цвет зоны отслеживания */
        border-radius: 2px; /* округлось бегунка */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #59b8b9; /* цвет бегунка */
        border-radius: 2px; /* округлось бегунка */
    }
}
.depositsHeaderRow {
    display: flex;
    justify-content: space-between;
}
.depositsHeaderRowColumn {
    flex: 0 1 25%;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media (max-width: $md3+px) {
        font-size: 16px;
    }
    @media (max-width: $md4+px) {
        font-size: 14px;
    }
}
.depositsRow {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #9fb6b0;
    padding-top: 20px;
    margin-bottom: 20px;
}
.depositsRowColumn {
    flex: 0 1 25%;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.02em;
    @media (max-width: $md3+px) {
        font-size: 16px;
    }
    @media (max-width: $md4+px) {
        font-size: 14px;
    }
    color: #ffffff;

    span {
        font-family: "Poppins", sans-serif;
        font-weight: 800;
        font-size: 18px;
        letter-spacing: 0.02em;
        color: #ffffff;
        @media (max-width: $md3+px) {
            font-size: 16px;
        }
        @media (max-width: $md4+px) {
            font-size: 14px;
        }
    }
}
