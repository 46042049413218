@import '../../../styles/var';

.bannerSection {
  .bannerRow {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: 215px;
    padding-bottom: 150px;
    z-index: 9;
    @media(max-width: $md5+px) {
      padding-top: 80px;
    }
    .bannerCol6 {
      position: relative;
      flex: 0 0 auto;
      width: 50%;
      @media(max-width: $md5+px) {
        width: 100%;
      }
      .bannerTitle {
        font-weight: 800;
        font-size: 60px;
        line-height: 90px;
        color: #57E9EB;
        max-width: 950px;
        @media(max-width: $md1+px) {
          font-size: 32px;
          line-height: 37px;
          max-width: 280px;
          margin-bottom: 15px;
        }
        @media(max-width: $md6+px) {
          font-size: 25px;
          line-height: 37px;
        }
      }
      .bannerText {
        margin: 30px 0 50px 0;
        width: 793px;
        font-weight: 300;
        font-size: 30px;
        line-height: 45px;
        max-width: 790px;
        @media(max-width: $md5+px) {
          font-size: 15px;
          line-height: 22px;
          max-width: 360px;
          margin-top: 0;
        }
      }
      .imageWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: $md5+px) {
          margin-top: 100px;
        }
        .imgFluid {
          max-width: 100%;
          height: auto;
          @media(max-width: $md5+px) {
            max-width: 250px;
          }
        }
      }
      .coin1 {
        position: absolute;
        left: 0;
        top: 80px;
        transition: all .3s;
        @media(max-width: $md5+px) {
          display: none;
          width: 60px;
          top: 100px;
          z-index: 1;
        }
      }
      .coin2 {
        position: absolute;
        left: 150px;
        top: -100px;
        transition: all .3s;
        @media(max-width: $md5+px) {
          display: none;
          width: 90px;
          top: 30px;
          left: 80px;
          z-index: 1;
        }
      }
      .coin3 {
        position: absolute;
        bottom: 100px;
        left: 0px;
        transition: all .3s;
        @media(max-width: $md5+px) {
          display: none;
          width: 80px;
          left: 50px;
          z-index: 1;
        }
      }
      .coin4 {
        position: absolute;
        right: 0;
        top: 150px;
        transition: all .3s;
        @media(max-width: $md5+px) {
          display: none;
          width: 110px;
          z-index: 1;
        }
      }
    }
  }
}

