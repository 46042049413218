@import '../../../styles/var';

.moreForexSection {
  .moreForexTitle {
    font-size: 50px;
    text-align: center;
    max-width: 920px;
    margin: 0 auto 25px;
    @media (max-width: $md3+px) {
      font-size: 25px;
      margin-bottom: 0;
    }
  }
  .moreForexRow {
    display: flex;
    flex-wrap: wrap;
    @media(max-width: $md5+px) {
      flex-direction: column-reverse;
    }
    .moreForexCol4 {
      flex: 0 0 auto;
      width: 25%;
      @media(max-width: $md5+px) {
        width: 100%;
      }
      .imgFluid {
        max-width: 100%;
        height: auto;
      }
    }
    .moreForexCol8 {
      flex: 0 0 auto;
      width: 75%;
      overflow-x: auto;
      overflow-y: hidden;
      @media(max-width: $md5+px) {
        width: 100%;
        margin-top: 30px;
      }
      .moreForexListWrap {
        display: flex;
        flex-direction: column;
        margin-top: 130px;
        @media(max-width: $md5+px) {
          width: 100%;
          margin-top: 30px;
        }
        @media (max-width: $md2+px) {
          width: 952px;
          margin-bottom: 10px;
        }
        @media (max-width: $md3+px) {
          width: 850px;
        }
        @media (max-width: $md5+px) {
          width: 520px;
        }
        .moreForexList {
          display: flex;
          .moreForexItem {
            font-size: 20px;
            font-weight: 300;
            flex: 0 0 auto;
            width: 33.33333%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-bottom: 50px;
            @media (max-width: $md3+px) {
              font-size: 14px;
              margin-bottom: 35px;
            }
            &:before {
              content: '';
              width: 11px;
              height: 11px;
              margin-bottom: 20px;
              background-color: #57E9EB;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
