@import '../../../styles/var';

.qa {
    margin-top: 170px;
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .header {
        text-align: center;
    }

    .faqWrapper {
        width: 1150px;
        margin: 50px auto 0 auto;
        @media (max-width: $md3+px) {
            width: 100%;
        }
    }
}