@import "../../../styles/_var.scss";

.header {
    z-index: 10;
    position: relative;
}

.headerBody {
}
.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    position: relative;
}
.headerRowLogo {
    position: relative;
    transition: all 0.3s linear;
    position: relative;
    z-index: 12;
    &:hover {
        transform: scale(1.02);
    }
    a {
    }
    img {
        height: 80px;
        @media (max-width: $md1+px) {
            height: 70px;
        }
        @media (max-width: $md2+px) {
            height: 60px;
        }
    }
}

.headerRowCenterWrapper {
    @media (max-width: $md4+px) {
        position: fixed;
        z-index: 10;
        top: 0px;
        right: 0px;

        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: auto;
        display: block;
        text-align: center;
        opacity: 0;
        transition: all 0.3s linear;
        background-color: #000;
        &.active {
            opacity: 1;
            pointer-events: visible;
        }
    }
    @media (max-width: $md5+px) {
        width: 100%;
        height: 100%;
        position: fixed;
    }
}
.headerRowCenter {
    width: 923px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    @media (max-width: $md1+px) {
        width: 750px;
    }
    @media (max-width: $md3+px) {
        width: 600px;
    }
    @media (max-width: $md4+px) {
        width: 100%;
        max-width: 100%;
    }
    a {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 19px;
        color: #ffffff;

        &.active {
            color: #57e9eb;
        }
        transition: all 0.3s linear;
        position: relative;
        z-index: 16;
        &:hover {
            // transform: scale(0.95);
        }
        @media (max-width: $md1+px) {
            font-size: 17px;
        }
        @media (max-width: $md2+px) {
            font-size: 16px;
        }
        @media (max-width: $md3+px) {
            font-size: 13px;
        }
    }
    @media (max-width: $md4+px) {
        display: block;
        width: 100%;
        height: 643px;
        text-align: left;

        padding: 120px 10px 10px 10px;
        position: relative;

        a {
            font-size: 20px !important;
            display: block;
            text-align: center;
            font-size: 20px;

            margin-bottom: 32px;

            @media (max-height: 480px) {
                margin-bottom: 10px;
                font-size: 24px;
            }
            @media (max-height: 400px) {
                font-size: 19px !important;
            }
        }
    }
    @media (max-width: $md5+px) {
        height: 100%;
        padding: 120px 18px 20px 18px;
    }
    @media (max-height: 573px) and (max-width: 992px) {
    }
    @media (max-height: 515px) and (max-width: 992px) {
    }
}
.headerRowCenterItem {
}
.headerRowRight {
    display: flex;
    align-items: center;
    z-index: 11;
}

.headerRowRightLocalization {
    position: relative;
    z-index: 25;
    margin-left: 60px;
    @media (max-width: $md1+px) {
        margin-left: 30px;
    }
    @media (max-width: $md3+px) {
        margin-left: 15px;
    }
    @media (max-width: $md4+px) {
        display: none;
    }
    &.mob {
        display: none;
        @media (max-width: $md4+px) {
            display: block;
            margin: 0 auto;
        }
    }
}
.wrap2 {
    display: flex;
}
.headerRowRightLocalizationTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    color: #ffffff;

    transition: all 0.3s linear;

    @media (max-width: $md1+px) {
        font-size: 14px;
    }

    &.active {
        img {
            transform: rotate(180deg);
        }
    }
    img {
        transition: all 0.3s linear;
        margin-left: 5px;
    }
}
.headerRowRightLocalizationItems {
    position: absolute;
    left: 2px;
    top: 30px;
    background: #fff;
    box-shadow: 0px 4px 14px rgba(122, 93, 197, 0.1);
    color: #000;
    padding: 5px 0;

    p {
        font-family: "Poppins", sans-serif;
        padding: 5px 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    display: none;
    &.active {
        display: block;
    }
}
.headerRowRightSignBtn {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    position: relative;
    z-index: 10;
    margin-left: 60px;
    @media (max-width: $md1+px) {
        margin-left: 30px;
    }
    @media (max-width: $md1+px) {
        font-size: 18px;
    }
    @media (max-width: $md2+px) {
        font-size: 16px;
    }
    @media (max-width: $md3+px) {
        font-size: 14px;
        margin-left: 15px;
    }
}
.headerRowRightOpenBtn {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    position: relative;
    z-index: 10;
    margin-left: 60px;
    @media (max-width: $md1+px) {
        margin-left: 30px;
    }
    @media (max-width: $md1+px) {
        font-size: 18px;
    }
    @media (max-width: $md2+px) {
        font-size: 16px;
    }
    @media (max-width: $md3+px) {
        font-size: 14px;
        margin-left: 15px;
    }
    @media (max-width: $md4+px) {
        margin-left: 30px;
    }
}

.headerRowRightBurger {
    position: relative;
    height: 23px;
    width: 32px;
    margin-left: 54px;
    z-index: 10;
    display: none;
    cursor: pointer;
    position: relative;

    &:after {
        content: "";
        display: "inline-block";
        left: -10px;
        top: -8px;
        position: absolute;
        width: 50px;
        height: 40px;
    }
    @media (max-width: $md5+px) {
        margin-left: 24px;
    }
    @media (max-width: $md4+px) {
        margin-left: 30px;
        display: block;
    }
    span {
        pointer-events: none;
        position: absolute;
        width: 32px;
        transition: all 0.3s linear;
        height: 3px;
        background-color: #57e9eb;
        &:first-child {
            right: 0;
            top: 0;
            width: 32px;
        }
        &:nth-child(2) {
            top: 0;
            bottom: 0;
            right: 0;
            width: 20px;
            margin: auto;
        }
        &:last-child {
            bottom: 0;
            width: 32px;
            right: 0;
        }
    }
    &.active {
        span {
            &:first-child {
                left: 0;
                top: 11px;

                margin: auto;
                width: 32px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                transform: rotate(-45deg);
                width: 32px;
            }
            &:last-child {
                opacity: 0;
            }
        }
    }
}
