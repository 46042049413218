@import '../../../styles/var';

.awardWinning {
    margin-top: 250px;
    display: flex;
    @media(max-width: $md3+px) {
        display: block;
    }
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .left, .right {
        width: 50%;
        @media(max-width: $md3+px) {
            width: 100%;
        }
    }
    .block {
        &_01 {

        }
        &_02 {
            margin-top: 30px;
        }
        &_03 {
            @media(max-width: $md3+px) {
                margin-top: 30px;
            }
        }
    }
    .header {

    }
    .text {
        margin-top: 30px;
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        width: 661px;
        @media(max-width: $md1+px) {
            width: 540px;
            font-size: 18px;
        }
        @media(max-width: $md5+px) {
            width: 100%;
        }
        @media(max-width: $md6+px) {
            font-size: 13px;
            line-height: 20px;
        }
    }
}