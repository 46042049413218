@import "../../../styles/_var.scss";
.spreads {
    position: relative;
    z-index: 2;
    margin: 100px 0;
    @media (max-width: $md4+px) {
        margin: 150px 0 70px 0;
    }
    @media (max-width: $md6+px) {
        margin: 150px 0 20px 0;
    }
}
.spreadsBody {
}
.spreadsRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}
.spreadsRowLeft {
    flex: 0 1 660px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
    }
}
.spreadsRowLeftTitle {
    text-align: left !important;
    margin-bottom: 15px;
    &.hide {
        opacity: 0;
        @media (max-width: $md4+px) {
            display: none;
        }
    }
}

.spreadsRowLeftSub {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 25px;
    line-height: 1.3;
    color: #57e9eb;
    margin-bottom: 35px;
    @media (max-width: $md3+px) {
        font-size: 24px;
        margin-bottom: 30px;
    }
    @media (max-width: $md4+px) {
        font-size: 22px;
        margin-bottom: 25px;
    }
    @media (max-width: $md5+px) {
        font-size: 20px;
        margin-bottom: 20px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
        margin-bottom: 15px;
    }
}
.spreadsRowLeftSubtitle {
    margin-bottom: 20px !important;
    font-size: 20px !important;
    text-align: left !important;

    span {
        display: block;
    }

    @media (max-width: $md5+px) {
        font-size: 18px !important;
    }
    @media (max-width: $md6+px) {
        font-size: 14px !important;
    }
}

.spreadsRowRight {
    flex: 0 1 660px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 470px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-top: 20px;
    }
}
.spreadsRowRightTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 25px;
    line-height: 1.3;
    color: #57e9eb;
    margin-bottom: 35px;
    @media (max-width: $md3+px) {
        font-size: 24px;
        margin-bottom: 30px;
    }
    @media (max-width: $md4+px) {
        font-size: 22px;
        margin-bottom: 25px;
    }
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}
.spreadsRowRightSubtitle {
    font-size: 20px !important;
    margin-bottom: 20px;
    text-align: left !important;
    @media (max-width: $md5+px) {
        font-size: 18px !important;
    }
    @media (max-width: $md6+px) {
        font-size: 14px !important;
    }
}
.spreadsRowRightWhiteText {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 20px;

    @media (max-width: $md6+px) {
        font-size: 18px;
        margin-bottom: 15px;
    }
}
